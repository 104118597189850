<template>
 <div class="success">
   <img src="@/assets/successIcon.jpg" class="icon" style="width: 100px;height: 100px">
   <div class="title">申请成功</div>
   <div class="date">提现申请提交成功，预计到账时间: {{$timestampToString(arrivalTime)}}</div>
   <div class="btns">
     <el-button type="info" @click="$router.replace('/income')">返回我的收益</el-button>
     <el-button type="primary" @click="$router.replace('/incomeList')">继续提现</el-button>
   </div>
 </div>
</template>

<script>
export default {
  props:{
    name:{
      type:String,
      default:'',

    }
  },
  data () {
    return {
   //   获取提现时间
      arrivalTime:""
    }
  },
  created() {
       this.$post('/api/wallet/moneyAndIncomeDetail',{}).then((res)=>{
        this.arrivalTime=res.data.arrivalTime

    })
  }
}
</script>

<style scoped lang="scss">
.success{
  padding-top: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .icon{
    width: 234px;
    height: 234px;
  }
  .title{
    color: #333333;
    font-size: 16px;
  }
  .date{
    font-size: 14px;
    color: #666660;
    margin-top: 12px;
    margin-bottom: 30px;
  }
  .btns{
    display: flex;
    align-items: center;
    .btn{
      width: 124px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
      cursor: pointer;
    }
    .back{
      background: #EFEFEF;
      color: #FF004D;
    }
    .goon{
      background: #FF004D;
      color: #ffffff;
      margin-left: 21px;
    }
  }
}
</style>
