<template>
  <div>
    <success></success>
  </div>
</template>

<script>
import Success from "../components/withdrawal/Success"

export default {
  name: "WithdrawalSuccess",
  components: {
    Success
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
